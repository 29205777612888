import React from 'react';
import Layout from '../components/organisms/Layout';
import Container from '../components/organisms/Container';
import { Search } from '../components/organisms/search';

const searchIndices = [{ name: `pages`, title: `Pages`, hitComp: `PageHit` }];

const SearchPage = ({ location }) => {
  const search = { showSearch: false };

  return (
    <Layout search={search}>
      <Container px={[3, 4]}>
        <Search indices={searchIndices} location={location} />
      </Container>
    </Layout>
  );
};

export default SearchPage;
